import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import dropdownService from './dropdownService';

export interface DropdownData{
    isError: boolean,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    projects: any,
    lots: any,  
    job_types: any,
    sovItems:any,
    supervisors: any,
    company_numbers: any,
    division_numbers: any,
    garage_orientation: any,
    proposal_numbers:any,
    contract_numbers:any,
    budget_revision_numbers:any,
    statuses: any
};
    
const initialState: DropdownData = {
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
    projects: [],
    lots: [],
    job_types: [],
    sovItems: {},
    supervisors: [],
    company_numbers: [],
    division_numbers: [],
    proposal_numbers: [],
    contract_numbers: [],
    budget_revision_numbers: [],
    garage_orientation: [
        "R",
        "L"
    ],
    statuses: [
        "ACTIVE",
        "PENDING",
        "INACTIVE"
    ]
};

// Get purchase orders
export const getProjectList = createAsyncThunk('get/project-list', async (req:any, thunkAPI: any) => {
    try {
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getLotList = createAsyncThunk('get/lot-list', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getJobTypes = createAsyncThunk('get/job-types', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getSupervisors = createAsyncThunk('get/supervisors', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getSovItems = createAsyncThunk('get/sov-items', async (req:any, thunkAPI: any) => {
    try {     
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getPlanOptions = createAsyncThunk('get/plan-options', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getCompanyNumbers = createAsyncThunk('get/company_numbers', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getDivisionNumbers = createAsyncThunk('get/division_numbers', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getContractNumbers = createAsyncThunk('get/contract_numbers', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getProposalRevisions = createAsyncThunk('get/proposal_numbers', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getBudgetRevisions = createAsyncThunk('get/budget_revisions', async (req:any, thunkAPI: any) => {
    try {
        
        return await dropdownService.getDropdowns(req);
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const dropdownSlice = createSlice({
    name: 'dropdowns',
    initialState,
    reducers: {
        reset: (_state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectList.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getProjectList.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.projects = action.payload?.data;
            })
            .addCase(getProjectList.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getSupervisors.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getSupervisors.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.supervisors = action.payload;
            })
            .addCase(getSupervisors.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getSovItems.pending, (state:any) => {
                state.sovItems = []; 
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;           
            })
            .addCase(getSovItems.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload?.message;
                state.sovItems = action.payload?.data;
            })
            .addCase(getSovItems.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getPlanOptions.pending, (state:any) => {
                state.isLoading = true;
                state.options = [];            
            })
            .addCase(getPlanOptions.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.options = action.payload;
            })
            .addCase(getPlanOptions.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getLotList.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getLotList.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.lots = action.payload?.data;
            })
            .addCase(getLotList.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getJobTypes.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getJobTypes.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.job_types = action.payload?.data;            
            })
            .addCase(getJobTypes.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getCompanyNumbers.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getCompanyNumbers.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.company_numbers = action.payload;            
            })
            .addCase(getCompanyNumbers.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getDivisionNumbers.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getDivisionNumbers.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.division_numbers = action.payload;           
            })
            .addCase(getDivisionNumbers.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getContractNumbers.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getContractNumbers.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.contract_numbers = action.payload?.data;           
            })
            .addCase(getContractNumbers.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getProposalRevisions.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getProposalRevisions.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.proposal_numbers = action.payload?.data;           
            })
            .addCase(getProposalRevisions.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
            .addCase(getBudgetRevisions.pending, (state:any) => {
                state.isLoading = true;             
            })
            .addCase(getBudgetRevisions.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.budget_revision_numbers = action.payload?.data;           
            })
            .addCase(getBudgetRevisions.rejected, (state:any, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message || ""; 
            })
    } 
});

export const {reset} = dropdownSlice.actions;
export default dropdownSlice.reducer;
