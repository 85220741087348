import { useState, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { pageLinks } from "../../layout/PageLinks";

const usePageLinks = () => {
    const {isAuthenticated, userDetails } = useAppSelector((state: any) => state.auth);
    const [filteredLinks, setFilteredLinks] = useState<any[]>([]);

    useEffect(() => {
        let links = [];
        // Add admin links if user has admin access
        if (userDetails?.groups?.includes('Admin')) {
            links.push(...pageLinks.admin);
        }
        // Always add app links
        links.push(...pageLinks.app);

        // Add warehouse links if user has warehouse access
        if (userDetails?.groups?.includes('Warehouse')) {
            links.push(...pageLinks.warehouse);
        }

        setFilteredLinks(links);
    }, [isAuthenticated, userDetails?.groups]); 

    return filteredLinks;
}

export default usePageLinks;