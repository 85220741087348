import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import apiService from './reportService';
import fileDownload from 'js-file-download';

export interface API_Data {
    isError: boolean,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
    jcReportItems: any,
    contractItems: any,
};
  
  
const initialState: API_Data = {
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
    jcReportItems: [],
    contractItems: [],
};

export const getJCReportByStartIds = createAsyncThunk('get/jc-report-by-ids', async (req:any, thunkAPI: any) => {
    try {
        const data = await apiService.getJobCostReportByStartIds(req);
        fileDownload(data, `${req?.fileName}.csv`);
        return;
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getOrdProcEnt = createAsyncThunk('get/ord-proc-ent', async (req:any, thunkAPI: any) => {
    try {
        const data = await apiService.getOrdProcEnt(req);
        fileDownload(data, `${req?.fileName}.csv`);
        return;
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getProjectTemplate = createAsyncThunk('get/project-templaste', async (req:any, thunkAPI: any) => {
    try {
        const data = await apiService.getProjectTemplate(req);
        fileDownload(data, 'project-template.xlsx');
        return;
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getCiImportByStartIds = createAsyncThunk('get/ci-import-by-ids', async (req:any, thunkAPI: any) => {
    try {
        const data = await apiService.getCiImportByStartIds(req);
        fileDownload(data, `${req?.fileName}.csv`);
        return;
    } catch (error: any) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        reset: (_state:any) => initialState,
    },
    extraReducers: (builder) => {
        builder
        .addCase(getOrdProcEnt.pending, (state:any) => {
            state.isLoading = true;             
        })
        .addCase(getOrdProcEnt.fulfilled, (state:any, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action?.payload?.message;
        })
        .addCase(getOrdProcEnt.rejected, (state:any, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload; 
        })
        .addCase(getJCReportByStartIds.pending, (state:any) => {
            state.isLoading = true;             
        })
        .addCase(getJCReportByStartIds.fulfilled, (state:any, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action?.payload?.message;
        })
        .addCase(getJCReportByStartIds.rejected, (state:any, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload; 
        })
        .addCase(getProjectTemplate.pending, (state:any) => {
            state.isLoading = true;             
        })
        .addCase(getProjectTemplate.fulfilled, (state:any, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action?.payload?.message;
        })
        .addCase(getProjectTemplate.rejected, (state:any, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload; 
        })
        .addCase(getCiImportByStartIds.pending, (state:any) => {
            state.isLoading = true;             
        })
        .addCase(getCiImportByStartIds.fulfilled, (state:any, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action?.payload?.message;
        })
        .addCase(getCiImportByStartIds.rejected, (state:any, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload; 
        })
    } 
});

export const {reset} = reportSlice.actions;
export default reportSlice.reducer;
