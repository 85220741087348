import { useState, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { RouteType, suites } from "./routes";

const useRouteList = () => {
    const { userDetails } = useAppSelector((state: any) => state.auth);
    const [filteredRoutes, setFilteredRoutes] = useState<any[]>([]);
    useEffect(() => {
        let routes: Set<RouteType> = new Set();
        // Always add root and app routes
        routes.add(suites.root);
        Object.values(suites.app.routes).forEach((route: any) => routes.add(route));

        // Check if userDetails is defined and add warehouse routes only if the user is in the 'Warehouse' group
        if (userDetails && userDetails?.groups?.includes('Warehouse')) {
            Object.values(suites.warehouse.routes).forEach((route: any) => routes.add(route));
        }

        if (userDetails && userDetails?.groups?.includes('Assurance')) {
            Object.values(suites?.assurance?.routes).forEach((route: any) => routes.add(route));
        }

        setFilteredRoutes(Array.from(routes));
    }, [userDetails, suites]); 

    return filteredRoutes;
}

export default useRouteList;