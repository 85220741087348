import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store} from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { LicenseInfo } from '@mui/x-data-grid-premium';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || "");

const container = document.getElementById('root')!;
const root = createRoot(container);

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
  root.render(<React.StrictMode>{app}</React.StrictMode>);
} else {
  root.render(app);
}


