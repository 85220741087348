import React, { useState, useEffect, useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout, reset, setSuiteDetails } from '../auth/authSlice';
import {Link, useTheme, FormControl, InputLabel, Select } from '@mui/material';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Icon for dark theme
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Icon for light theme
import { toggleSidebar, toggleThemeMode } from './layoutSlice';
import {ReactComponent as LogoSVG} from '../../assets/Logo.svg';
import { useGetCompanyNumbersQuery, useGetDivisionNumbersQuery } from '../dropdowns/dropdownApi';
import usePageLinks from '../components/hooks/usePageLinks';

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {isSidebarOpen, themeMode, isMobile} = useAppSelector((state:any) => state.layout);
  const {company, division, suiteDetailsConfirmed}= useAppSelector((state:any) => state.auth);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [companySelection, setCompanySelection] = useState<any>({});
  const [divisionSelection, setDivisionSelection] = useState<any>({});
  const [logoutUser] = useState(false);
  const { data: company_numbers } = useGetCompanyNumbersQuery();
  const params = useMemo(() => ({ company_id: companySelection?._id }), [companySelection]);
  const { data: division_numbers } = useGetDivisionNumbersQuery(params);
  const theme = useTheme();
  const userGuideLink = process.env.REACT_APP_USER_GUIDE_LINK || "";

  const pages = usePageLinks();

  useEffect(() => {
    if (company && division) {
      setCompanySelection(company);
      setDivisionSelection(division);
    }
  }, [company, division]);

  const appBarStyle = {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    borderBottom: `0.25em solid ${theme.palette.primary.light}`,
    borderRadius: 0.5,
    color: theme.palette.text.primary,
    height: '64px',
    boxShadow: 2,
    opacity: 1,
    zIndex: 1
  };
  
  useEffect(() => {
    if (
      companySelection &&
      Object.keys(companySelection).length > 0 &&
      divisionSelection &&
      Object.keys(divisionSelection).length > 0
    ) {
      dispatch(
        setSuiteDetails({
          company: companySelection,
          division: divisionSelection,
        })
      );
    }
  }, [companySelection, divisionSelection, dispatch]);    
  
  const handleCompanyNumberChange = (event:any) => {
    setCompanySelection(event.target.value);
  };

  const handleDivisionNumberChange = (event:any) => {
    setDivisionSelection(event.target.value);
  };

  const  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleSidebar());
    setAnchorElNav(event.currentTarget);
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {   
    if(logoutUser){
        navigate('/login')
    }  
}, [logoutUser, navigate]);

  const handleToggleTheme = () => {
    dispatch(toggleThemeMode()); // Dispatch action to toggle theme mode
  };


  const handleCloseNavMenu = () => {
    dispatch(toggleSidebar());
    setAnchorElNav(null);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(reset());
    navigate('/login');
  };

  return (
    <>
      {suiteDetailsConfirmed &&
        <AppBar position="relative" sx={appBarStyle} >
          <Toolbar disableGutters>
            <Box sx={{ml: "0.5em", width: "50%", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "1vw" }}>
              <IconButton
                  size="large"
                  onClick={handleClick}
                  color="inherit"
                  disableRipple
                  sx={{m: "0.45em"}}
                >
                  {!isSidebarOpen ? 
                    <Box>
                      <MenuSharpIcon fontSize='medium' /> 
                    </Box>
                    : 
                    <Box>
                        <MenuOpenSharpIcon fontSize='medium' />
                    </Box>
                    }
              </IconButton>
              {isMobile && (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  open={isSidebarOpen}
                  onClose={handleCloseNavMenu}
                > 
                  {pages.map((page) => (
                    <MenuItem key={page.id} onClick={() => {
                      handleCloseNavMenu();
                      navigate(page.link);
                    }}>
                      <Typography textAlign="center">{page.title}</Typography>
                    </MenuItem>
                  ))}

                  <MenuItem>
                    <React.Fragment>
                      <Box sx={{display: "flex",flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                      <FormControl fullWidth sx={{ width: 80 }}>
                        <InputLabel>C#</InputLabel>
                        <Select
                          value={companySelection?.company_number || ''}
                          label="C#"
                          size="small"
                          onChange={handleCompanyNumberChange}
                          renderValue={(value: any) => (value ? value : <em>Nothing Selected</em>)}
                        >
                          {company_numbers?.data?.map((item: any) => (
                            <MenuItem key={item?.company_number} value={item}>
                              {item?.company_number}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        fullWidth
                        sx={{ marginLeft: isMobile ? 0 : 0.5, marginTop: isMobile ? 0.5 : 0, width: 80 }}
                      >
                        <InputLabel>D#</InputLabel>
                        <Select
                          value={divisionSelection?.division_number || ''}
                          label="D#"
                          size="small"
                          onChange={handleDivisionNumberChange}
                          renderValue={(value: any) => (value ? value : <em>Nothing Selected</em>)}
                        >
                          {division_numbers?.data?.map((item: any) => (
                            <MenuItem key={item?.division_number} value={item}>
                              {item?.division_number}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      </Box>

                      
                    </React.Fragment>
                  </MenuItem>
                </Menu>
              )}
                <Box 
                  sx={{height: "64px", width: "128px", display: "flex", justifyContent: "center", alignItems: "center"}} 
                  onClick={() => navigate("/")}
                >
                  <LogoSVG 
                    style={{
                      height: "3rem", 
                      width: "6rem",
                      opacity: 1, 
                      fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main}}
                    />
                </Box>
                {!isMobile &&
                <React.Fragment>
                  <Box sx={{width: '240px', display: "flex",flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                  <FormControl fullWidth sx={{ width: 80 }}>
                    <InputLabel>C#</InputLabel>
                    <Select
                      value={companySelection?.company_number || ''}
                      label="C#"
                      size="small"
                      onChange={handleCompanyNumberChange}
                      renderValue={(value: any) => (value ? value : <em>Nothing Selected</em>)}
                    >
                      {company_numbers?.data?.map((item: any) => (
                        <MenuItem key={item?.company_number} value={item}>
                          {item?.company_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    fullWidth
                    sx={{ marginLeft: isMobile ? 0 : 0.5, marginTop: isMobile ? 0.5 : 0, width: 80 }}
                  >
                    <InputLabel>D#</InputLabel>
                    <Select
                      value={divisionSelection?.division_number || ''}
                      label="D#"
                      size="small"
                      onChange={handleDivisionNumberChange}
                      renderValue={(value: any) => (value ? value : <em>Nothing Selected</em>)}
                    >
                      {division_numbers?.data?.map((item: any) => (
                        <MenuItem key={item?.division_number} value={item}>
                          {item?.division_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Box>
                </React.Fragment>}
            </Box>

            <Box sx={{
              display: 'flex', 
              justifyContent: 'flex-end', 
              alignItems: 'center', 
              right: 0, 
              width: '50%', 
              height: '100%', 
              mr: "2em",
              color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main
              }}>
              <IconButton
                sx={{ opacity: "0.8", mr: 2 }}
                onClick={handleToggleTheme}
                color="inherit"
              >
                {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />} {/* Toggle icon based on current theme */}
              </IconButton>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="user" src="" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key={0}>
                  <Typography textAlign="center">
                    <Link href={userGuideLink} underline="none" target="_blank" rel="noopener noreferrer">
                      User Guide
                    </Link>  
                  </Typography>
                </MenuItem>
                <MenuItem key={1} onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                  
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      }
    </>
  );
}

export default ResponsiveAppBar;