import cemexAPI from '../api/cemexAPI';
import appAPI from '../api/appAPI';
import { OrdProcEntRequest, APIResponse } from './types';

const getTargetInstance = (service:string) => {
    switch(service){
        case "cemex":
            return cemexAPI;
        case "app":
            return appAPI;
        default:
            return appAPI;
    }
}
// Get user projects
const getJobCostReport = async (req:any) => {

    const url = req.url;
    const response = req?.data ? await appAPI.get(`/reports/cost-report?${url}`, req?.data)  : await appAPI.get(`/reports/cost-report?${url}`);
    
    return response.data;
}

const getProjectTemplate = async (_req:any) => {

    const response = await appAPI.get(`/projects-template`, {responseType: 'blob'});
    return response.data;
}

const getOrdProcEnt = async (req: OrdProcEntRequest): Promise<any> => {
    const instance = getTargetInstance(req.service || 'app');
    const order_ids = req?.body?.order_ids;
    if (Array.isArray(order_ids) && order_ids?.length > 1) {
        const response = await instance.put<APIResponse>(
            '/reports/ordprocent/export',
            req.body
        );
        return response.data;
    }

    const response = await instance.put<APIResponse>(
        `/reports/ordprocent/export${req.url ? `?${req.url}` : ''}`
    );
    return response.data;
};

const getJobCostReportByStartIds = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.put(`/reports/cost-report/export?${url}`, req?.data);
    return response.data;
}

const getCiImportByStartIds = async (req:any) => {
    const url = req.url;
    const instance = getTargetInstance(req.service);
    const response = await instance.put(`/reports/contract-items/export?${url}`, req?.data);
    return response.data;
}

const getContractItems = async (req:any) => {

    const url = req.url;
    const response = await appAPI.get(`/reports/contract-items?${url}`);
    return response.data;
}

const apiService = {
    getContractItems,
    getProjectTemplate,
    getJobCostReport,
    getJobCostReportByStartIds,
    getCiImportByStartIds, 
    getOrdProcEnt
}

export default apiService