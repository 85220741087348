import {createSlice } from '@reduxjs/toolkit';
import { createDoc, uploadDoc } from '../../projects/projectSlice';

export interface StepperData {
    isError: boolean,
    isLoading: boolean,
    isSuccess: boolean,
    status: string;
    formErrors: any; 
    message: string,
    items: any,
    selectedItem: any,
};

const initialState: StepperData = {
    isError: false,
    isLoading: false,
    isSuccess: false,
    status: "",
    formErrors: {}, 
    message: "",
    items: [],
    selectedItem: {}
};

export const stepper = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        reset: (_state) => initialState,
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        setStepperState: (state, action) => {
            state.message = action.payload.message;
            state.isError = action.payload.error ? true : false;
            state.isSuccess = !state.isError;
            state.status = action.payload.status;
        },
        setMessage: (state, action) => {
            state.message = action.payload.message;
        },
        setErrorState: (state, action) => {
            state.isError = action.payload;
        },
        setFormErrors: (state:any,action:any) => {
            state.formErrors = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createDoc.pending, (state:any) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";             
            })
            .addCase(createDoc.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                //state.selectedItem = action.payload.data;
                state.message = action.payload.message;
            })
            .addCase(createDoc.rejected, (state:any, action:any) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action?.payload?.formErrors?.['error'] || action.payload?.message || action.payload;
                state.formErrors = action?.payload?.formErrors;
            })
            .addCase(uploadDoc.pending, (state:any) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = "";             
            })
            .addCase(uploadDoc.fulfilled, (state:any, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.items = action.payload.data;
                state.message = action.payload.message;
            })
            .addCase(uploadDoc.rejected, (state:any, action:any) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action?.payload?.formErrors?.['error'];
                state.formErrors = action?.payload?.formErrors;
            })
    } 
});

export const {setSelectedItem, setMessage, setErrorState, setStepperState, reset, setFormErrors} = stepper.actions;
export default stepper.reducer;
